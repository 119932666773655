<template>
  <getecma-single-content-layout>
    <template #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl " />
      <div class="d--flex justify-content--space-between align-items--center">
        <getecma-header class="mt--md">Cidades</getecma-header>
        <div class="d--flex align-items--center" @keyup.enter="onSearchInput">
          <el-input
            v-model="searchQuery"
            placeholder="Buscar cidade"
            class="input-with-select"
            clearable
            @clear="onClearSearch">
            <el-button slot="append" icon="el-icon-search" @click="onSearchInput"></el-button>
          </el-input>
          <getecma-button v-if="isAdmin" style="margin-left: 5%;" @click="onCreateCity">
            Adicionar Cidade
          </getecma-button>
        </div>
      </div>
      <div class="d-flex scroll mb--lg justify-content-center" :style="isMinLayout() ? 'height: 50px;':''" @scroll="infinityScroll($event, cities)">
        <div v-if="cities.data.length" class="w-100">
          <div
            v-for="city in cities.data"
            :key="city.id"
            class="w-100 mb--md">
            <getecma-city-card
              :city="city" />
          </div>
        </div>
        <getecma-empty-box v-else description="Nenhuma cidade encontrada" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { goToCreateCity } from '@/modules/city/city.routes';
import { fetchCities } from '@/modules/city/city.service';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { toastError } from '@/services/toastService';
import { infinityScroll } from '@/helpers/scroll/infinity';
import { isMinLayout } from '@/helpers/layout/layout';

import GetecmaCityCard from '@/modules/city/components/CityCard.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaMySpaceCities',
  components: {
    GetecmaCityCard,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Cidades', path: this.$route.path },
      ],
      cities: { data: [], pagination: { page: 1, limit: 20, lastPage: 0 } },
      searchQuery: '',
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  mounted() {
    this.fetchCities();
  },
  methods: {
    isMinLayout,
    fetchCities() {
      this.cities.loading = true;
      fetchCities({ limit: this.cities.pagination.limit, page: this.cities.pagination.page, search: this.searchQuery })
        .then(({ cities, total_cities: total }) => {
          if (this.searchQuery === '') {
            this.cities.data.push(...cities);
          } else {
            this.cities.data = cities;
          }
          Object.assign(this.cities.pagination, { total: parseInt(total), lastPage: parseInt((total / this.cities.pagination.limit) + 1) });
        })
        .catch(() => toastError('Erro ao obter cidades.'))
        .finally(() => { this.cities.loading = false; });
    },
    onCreateCity() {
      goToCreateCity(this.$router);
    },
    infinityScroll(event, content) {
      infinityScroll(event, content)
        .then(() => {
          this.fetchCities();
        })
        .catch(() => {});
    },
    onSearchInput() {
      this.cities.pagination.page = 1;
      this.cities.pagination.lastPage = 0;
      this.cities.data = [];
      this.fetchCities();
    },
    onClearSearch() {
      this.searchQuery = '';
      this.onSearchInput();
    },
  },
};
</script>
